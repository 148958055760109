@import '../../settings';

.Appbar_logo {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    position: relative;
    text-decoration: none;

    .Appbar_logo_image {
        margin-top: 2px;
        flex: 1;
        display: flex;
    }

    .Appbar_logo_title {
        display: flex;
        justify-content: center;
        align-items: center;        
        line-height: 12px;
        flex: 1;
        bottom: 1px;
        width: 100%;
        color: #005A50;
        text-align: center;
    }

    @include resp('xs') {
        width: $app-bar-height-xs + 10;
        height: $app-bar-height-xs;
        font-size: 0;
        .Appbar_logo_image {
            width: $app-bar-height-sm / 2 ;
            height: $app-bar-height-sm / 2;
        }
    }
    @include resp('sm') {
        width: $app-bar-height-sm + 10;
        height: $app-bar-height-sm;
        font-size: $font-size-sm - 4;
        .Appbar_logo_image {
            width: $app-bar-height-sm / 2;
            height: $app-bar-height-sm / 2;
        }
    }
    @include resp('md') {
        width: $app-bar-height-md + 10;
        height: $app-bar-height-md;
        font-size: $font-size-md - 6;
        .Appbar_logo_image {
            width: $app-bar-height-md / 2;
            height: $app-bar-height-md / 2;
        }
    }
    @include resp('lg') {
        width: $app-bar-height-lg + 10;
        height: $app-bar-height-lg;
        font-size: $font-size-lg - 6;
        .Appbar_logo_image {
            width: $app-bar-height-lg / 2;
            height: $app-bar-height-lg / 2;
        }
    }
    @include resp('xlg') {
        width: $app-bar-height-xlg + 10;
        height: $app-bar-height-xlg;
        font-size: $font-size-lg - 6;
        .Appbar_logo_image {
            width: $app-bar-height-xlg / 2;
            height: $app-bar-height-xlg / 2;
        }
    }
}