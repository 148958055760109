@import '../../settings';

.closeDialog {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
    font-size: 24px;
    color: rgba(0,0,0,0.54);
}

.inviteFriend {
    color: #424242 !important;
    display: flex;
    overflow: hidden !important;
    flex-direction: row;
    justify-content: center;

    .__left {
        flex: 5;
        margin-right: 40px;

        @include resp('xs') {
            width: 100%;
            margin-right: 0px;
        }
        @include resp('sm') {
            width: 100%;
            margin-right: 0px;
        }

        a {
            text-decoration: none;
        }

        h4 {
            margin-bottom: 5px !important;
        }

        .shareViaEmail {
            margin-bottom: 40px;

            .emailInputField {
                margin-top: 40px !important;
                margin-right: 15px !important;
                height: 24px !important;
                min-width: 55% !important;
            
                input {
                    font-size: 15px !important;
                }
            
                p {
                    color: $app-red !important;
                }
            
                label {
                    font-size: 14px !important;
                }
            }

            .invitationMailSent {
                font-size: 14px !important;
                font-weight: 800;
                margin-top: -5px !important;
            }
        }

        .shareInvite {
            display: flex;
            flex-direction: row;
            height: 45px;
        
            .invitationLink {
                margin-right: 8px;
                border: 1px solid;
                border-radius: 2px;
                border-color: #EEEEEE;
                padding: 5px;
                height: 100%;
                min-width: 55%;
        
                input {
                    color: #757575 !important;
                    font-size: 15px !important;
                }

                p {
                    color: $md-primary !important;
                    cursor: pointer;
                    
                    &:hover {
                        font-weight: 800;
                    }
                }
            }
        
            .__fb {color: rgb(0, 132, 255)}
            .__wa {color: rgb(37, 211, 102)}

            i {
                cursor: pointer;
                font-size: 30px;
                margin: auto 8px;

                &:hover {
                    font-size: 34px;
                }
            }
        }
    }
    .__right {
        margin: auto 0;
        margin-top: 45px;
        padding: 20px 30px;
        flex: 2;
        border: 1px solid;
        border-radius: 2px;
        border-color: #E0E0E0;
        text-align: center;
        display: flex;        
        flex-direction: column;
        justify-content: space-between;

        .__child {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 25px;
            border-bottom: 1px solid;
            border-color: #EEEEEE;
            font-size: 15px;
        
            .__blue {
                color: $md-primary !important
            }
        }

        h3 {
            margin-bottom: 60px;
        }
        
        @include resp('xs') {
            display: none;
        }
        @include resp('sm') {
            display: none;
        }
    }
}