@import '../../settings.scss';

.PlayButton {
  margin: -4px !important;
}

.HeaderButton {
  border-radius: 0 !important;
  box-shadow: none !important;

  i {
    box-sizing: border-box;
    position: relative;
    padding: $Header__button-circle-gutter;
    border-radius: 100%;
    box-shadow: $app-btn-boxshadow;
    background: $md-primary;
  }

  strong {
    font-weight: normal;
    font-style: normal;
    margin-left: 10px;
    text-transform: none; // capitalize;
    font-weight: 600;
  }

  @include resp('xs') {
    height: $app-bar-height-xs;
    min-width: unset !important;
    strong {
      display: none;
    }
    i {
      height: $Header__button-circle-size;
      width: $Header__button-circle-size;
    }
    svg,
    img {
      height: $Header__button-circle-size - ($Header__button-circle-gutter * 2);
      width: $Header__button-circle-size - ($Header__button-circle-gutter * 2);
    }
  }
  @include resp('sm') {
    height: $app-bar-height-sm;
    min-width: unset !important;
    strong {
      display: none;
    }
    i {
      height: $Header__button-circle-size + 2;
      width: $Header__button-circle-size + 2;
    }
    svg,
    img {
      height: $Header__button-circle-size + 2 - ($Header__button-circle-gutter * 2);
      width: $Header__button-circle-size + 2 - ($Header__button-circle-gutter * 2);
    }
  }
  @include resp('md') {
    height: $app-bar-height-md;
    strong {
      display: block;
      font-size: $font-size-md - 2;
    }
    i {
      height: $Header__button-circle-size + 5;
      width: $Header__button-circle-size + 5;
    }
    svg,
    img {
      height: $Header__button-circle-size + 5 - ($Header__button-circle-gutter * 2);
      width: $Header__button-circle-size + 5 - ($Header__button-circle-gutter * 2);
    }
  }
  @include resp('lg') {
    height: $app-bar-height-lg;
    strong {
      display: block;
      font-size: $font-size-lg - 3;
    }
    i {
      height: $Header__button-circle-size + 8;
      width: $Header__button-circle-size + 8;
    }
    svg,
    img {
      height: $Header__button-circle-size + 8 - ($Header__button-circle-gutter * 2);
      width: $Header__button-circle-size + 8 - ($Header__button-circle-gutter * 2);
    }
  }
  @include resp('xlg') {
    height: $app-bar-height-xlg;
    strong {
      display: block;
      font-size: $font-size-xlg - 2;
    }
    i {
      height: $Header__button-circle-size + 8;
      width: $Header__button-circle-size + 8;
    }
    svg,
    img {
      height: $Header__button-circle-size + 8 - ($Header__button-circle-gutter * 2);
      width: $Header__button-circle-size + 8 - ($Header__button-circle-gutter * 2);
    }
  }
}
