@import '../../settings.scss';

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.SubtitleEditor {
  transition: all $Video-resize-transmition-time ease-in-out;
  width: 100%;  
  background: $Editor-background;

  // @include resp('xs') {
  //   // if the transcription has video
  //   &.withMediaElement {
  //     @media (max-width: $WavePlayer__video-breakpoint) {
  //       margin-top: $app-bar-height-xs + $md-toolbar-height-xs + $WavePlayer__waveform-height-xs + $WavePlayer__video-height;
  //     }
  //   }
  //   // if the video size is expanded
  //   &.isExpanded {
  //     @media (max-width: $WavePlayer__video-breakpoint) {
  //       margin-top: $app-bar-height-xs + $md-toolbar-height-xs + $WavePlayer__waveform-height-xs + $WavePlayer__video-height-expanded;
  //     }
  //   }
  //   margin-top: $app-bar-height-xs + $md-toolbar-height-xs + $WavePlayer__waveform-height-xs;
  // }
  // @include resp('sm') {
  //   &.withMediaElement {
  //     @media (max-width: $WavePlayer__video-breakpoint) {
  //       margin-top: $app-bar-height-sm + $md-toolbar-height-sm + $WavePlayer__waveform-height-sm + $WavePlayer__video-height;
  //     }
  //   }
  //   &.isExpanded {
  //     @media (max-width: $WavePlayer__video-breakpoint) {
  //       margin-top: $app-bar-height-sm + $md-toolbar-height-sm + $WavePlayer__waveform-height-sm + $WavePlayer__video-height-expanded;
  //     }
  //   }
  //   margin-top: $app-bar-height-sm + $md-toolbar-height-sm + $WavePlayer__waveform-height-sm;
  // }
  // @include resp('md') {
  //   &.withMediaElement {
  //     @media (max-width: $WavePlayer__video-breakpoint) {
  //       margin-top: $app-bar-height-md + $md-toolbar-height-md + $WavePlayer__waveform-height-md + $WavePlayer__video-height;
  //     }
  //   }
  //   &.isExpanded {
  //     @media (max-width: $WavePlayer__video-breakpoint) {
  //       margin-top: $app-bar-height-md + $md-toolbar-height-md + $WavePlayer__waveform-height-md + $WavePlayer__video-height-expanded;
  //     }
  //   }
  //   margin-top: $app-bar-height-md + $md-toolbar-height-md + $WavePlayer__waveform-height-md;
  // }
  // @include resp('lg') {
  //   &.withMediaElement {
  //     @media (max-width: $WavePlayer__video-breakpoint) {
  //       margin-top: $app-bar-height-lg + $md-toolbar-height-lg + $WavePlayer__waveform-height-lg + $WavePlayer__video-height;
  //     }
  //   }
  //   &.isExpanded {
  //     @media (max-width: $WavePlayer__video-breakpoint) {
  //       margin-top: $app-bar-height-lg + $md-toolbar-height-lg + $WavePlayer__waveform-height-lg + $WavePlayer__video-height-expanded;
  //     }
  //   }
  //   margin-top: $app-bar-height-lg + $md-toolbar-height-lg + $WavePlayer__waveform-height-lg;
  // }
  // @include resp('xlg') {
  //   margin-top: $app-bar-height-xlg + $md-toolbar-height-xlg + $WavePlayer__waveform-height-xlg;
  // }  
  margin-top: 105px;

  /**
   * The editor foreground (wrapper around `draft-js` element)
   */
  &__fg {
    box-sizing: border-box;
    position: relative;
    max-width: $Editor-maxwidth;
    margin: 0 auto;
    min-height: 100%;
    // padding: ($md-gutter) 0 $Editor-spacing-bottom;
    outline: none;
    white-space: pre-wrap; // as in trint.com
    line-height: 1.8;
    font-size: 18px;
    // background: $Editor-foreground;
    // color: $tttranscript__word-color-active;

    ::selection {
      background: hsla(270, 4.9%,71%, .38); // as in trint.com
    }
  }

  [data-block] {
    float: left;
    width: 100%;
  }
}

.blockSub {
  padding-bottom: $Editor__segment-horizontal-gutter + 2;
  background-color: $Editor-foreground;
}

/**
 * Transcript's row of content
 */
.block {
  position: relative;
  float: left;
  width: 100%;
  // padding: 0 $Editor__segment-horizontal-gutter

  .textLength {
    z-index: 1;
    position: absolute;
    font-size: 14px;
    top: $md-gutter / 4;
    right: $Editor__segment-horizontal-gutter;
    color: $Editor__word-color-inactive;
    user-select: none;
    color: $app-grey1;
    &.overLimit {
      color: $app-red;
    }
  }

  .txtSub {
    color: $Editor__word-color-inactive;
  }

  &.active .txtSub {
    color: $Editor__word-color-active;
  }


  &:hover {
    .txtSub {
      border-color: $Editor__speaker-input-bordercolor;
    }
    .SubtitleEditorSpeaker__input {
      color: $Editor__speaker-input-hover-color;
    }
    // .SubtitleEditorSpeaker {
    //   border-color: $Editor__speaker-input-bordercolor;
    // }
    // .SubtitleEditorSpeaker-placeholder {
    //   display: block;
    // }
  }

  .newLine {
    color: $Editor__word-color-inactive;
  }

  &.active .newLine {
    color: $Editor__word-color-active;
  }


  &:hover {
    .newLine {
      border-color: $Editor__speaker-input-bordercolor;
    }
    .SubtitleEditorSpeaker__input {
      color: $Editor__speaker-input-hover-color;
    }
    // .SubtitleEditorSpeaker {
    //   border-color: $Editor__speaker-input-bordercolor;
    // }
    // .SubtitleEditorSpeaker-placeholder {
    //   display: block;
    // }
  }
}

/**
 * A paragraph of text
 */
.txtSub {
  position: relative;
  margin: 0;
  background: $Editor-foreground;

  [data-block="true"]:last-child & {
    padding-bottom: $Editor-spacing-bottom;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: $Editor-spacing-bottom;
      background: linear-gradient(to bottom, $Editor-foreground 0%, $Editor-background 100%);
    }
  }
}

/**
 * A slate paragraph of text
 */
.draftJsTxtSub {
  padding: ($Editor__segment-horizontal-gutter + 2) $Editor__segment-horizontal-gutter;
}

/**
 * A slate paragraph of text
 */
.slateTxtSub {
  padding: 0 $Editor__segment-horizontal-gutter;
}

.newLine {
  position: relative;
  padding: ($Editor__segment-horizontal-gutter + 2) $Editor__segment-horizontal-gutter;
  padding-top: 0px !important;
  margin: 0;
  margin-top: -26px;
  background: $Editor-foreground;

  [data-block="true"]:last-child & {
    padding-bottom: $Editor-spacing-bottom;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: $Editor-spacing-bottom;
      background: linear-gradient(to bottom, $Editor-foreground 0%, $Editor-background 100%);
    }
  }
}

  /**
   * The tinmestamp of each block
   */
.timeSubtitle {
  z-index: 1;
  position: relative;
  font-size: 14px;
  top: $md-gutter / 4;
  left: 0;
  color: $md-primary;
  user-select: none;
  // pointer-events: none;
  & .start, & .end {
    cursor: pointer;
  }
}

.slateTimeSubtitle {
  font-size: 14px;
  top: $md-gutter / 4;
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  z-index: 1;
  color: $md-primary;

  span {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.SpeakerDropdown--open {
  #Header,
  .WavePlayer {
    padding-right: 15px;
  }
  .EditorControls {
    padding-right: 31px;
  }
}

.timeline_footer {
  z-index: 1000;
  display: flex;
  position: fixed;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: space-between;
  height: 90px;
  bottom: 50px;

}