@import '../../settings.scss';

.home {

  &__card {
    padding: 2rem;
    text-align: center;
    margin: auto;

    form {
      text-align: left;
    }

    @media (min-width: 600px) {
      width: 500px;
    }

    .login {
      margin-top: $md-gutter * 3;
    }
  }

  &__img {}

  &__title {
    text-align: center;
    margin: 0 0 -10px;
  }

  &__subtitle {
    margin: -$md-gutter 0 ($md-gutter * 3) 0;
    color: #444;
    text-align: right;
  }

  .auth__msg {
    text-align: center;
    color: #999;
  }

  .auth__btn.md-button {
    display: block;
    width: 100%;
    margin: 0;
    padding: .3rem 3rem;
    font-size: 18px;
  }
}
