.findWrapper {
    position: fixed;
    bottom: 165px;
    z-index: 1001;
    padding: 10px;
    width: 150px;
    background-color: rgb(234, 234,234);
    .buttonWrapper {
        display: flex;
        padding: 10px;
        flex-direction: column;
        gap: 5px;
    }
}