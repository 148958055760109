@import '../../settings.scss';

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.TranscriptEditor {
  transition: all $Video-resize-transmition-time ease-in-out;
  width: 100%;  
  background: $Editor-background;

  @include resp('xs') {
    // if the transcription has video
    &.withMediaElement {
      @media (max-width: $WavePlayer__video-breakpoint) {
        margin-top: $app-bar-height-xs + $md-toolbar-height-xs + $WavePlayer__waveform-height-xs + $WavePlayer__video-height;
      }
    }
    &.withMediaElementAndBanner {
      @media (max-width: $WavePlayer__video-breakpoint) {
        margin-top: $app-bar-height-xs + $md-toolbar-height-xs + $WavePlayer__waveform-height-xs + $WavePlayer__video-height + 92;
      }
    }
    // if the video size is expanded
    &.isExpanded {
      @media (max-width: $WavePlayer__video-breakpoint) {
        margin-top: $app-bar-height-xs + $md-toolbar-height-xs + $WavePlayer__waveform-height-xs + $WavePlayer__video-height-expanded;
      }
    }
    &.isExpandedWithBanner {
      @media (max-width: $WavePlayer__video-breakpoint) {
        margin-top: $app-bar-height-xs + $md-toolbar-height-xs + $WavePlayer__waveform-height-xs + $WavePlayer__video-height-expanded + 92;
      }
    }
    margin-top: $app-bar-height-xs + $md-toolbar-height-xs + $WavePlayer__waveform-height-xs;
  }
  @include resp('sm') {
    &.withMediaElement {
      @media (max-width: $WavePlayer__video-breakpoint) {
        margin-top: $app-bar-height-sm + $md-toolbar-height-sm + $WavePlayer__waveform-height-sm + $WavePlayer__video-height;
      }
    }
    &.withMediaElementAndBanner {
      @media (max-width: $WavePlayer__video-breakpoint) {
        margin-top: $app-bar-height-sm + $md-toolbar-height-sm + $WavePlayer__waveform-height-sm + $WavePlayer__video-height + 87;
      }
    }
    &.isExpanded {
      @media (max-width: $WavePlayer__video-breakpoint) {
        margin-top: $app-bar-height-sm + $md-toolbar-height-sm + $WavePlayer__waveform-height-sm + $WavePlayer__video-height-expanded;
      }
    }
    &.isExpandedWithBanner {
      @media (max-width: $WavePlayer__video-breakpoint) {
        margin-top: $app-bar-height-sm + $md-toolbar-height-sm + $WavePlayer__waveform-height-sm + $WavePlayer__video-height-expanded + 92;
      }
    }
    margin-top: $app-bar-height-sm + $md-toolbar-height-sm + $WavePlayer__waveform-height-sm;
  }
  @include resp('md') {
    &.withMediaElement {
      @media (max-width: $WavePlayer__video-breakpoint) {
        margin-top: $app-bar-height-md + $md-toolbar-height-md + $WavePlayer__waveform-height-md + $WavePlayer__video-height;
      }
    }
    &.withMediaElementAndBanner {
      @media (max-width: $WavePlayer__video-breakpoint) {
        margin-top: $app-bar-height-md + $md-toolbar-height-md + $WavePlayer__waveform-height-md + $WavePlayer__video-height + 83;
      }
    }
    &.isExpanded {
      @media (max-width: $WavePlayer__video-breakpoint) {
        margin-top: $app-bar-height-md + $md-toolbar-height-md + $WavePlayer__waveform-height-md + $WavePlayer__video-height-expanded;
      }
    }
    &.isExpandedWithBanner {
      @media (max-width: $WavePlayer__video-breakpoint) {
        margin-top: $app-bar-height-md + $md-toolbar-height-md + $WavePlayer__waveform-height-md + $WavePlayer__video-height-expanded + 92;
      }
    }
    margin-top: $app-bar-height-md + $md-toolbar-height-md + $WavePlayer__waveform-height-md;
  }
  @include resp('lg') {
    &.withMediaElement {
      @media (max-width: $WavePlayer__video-breakpoint) {
        margin-top: $app-bar-height-lg + $md-toolbar-height-lg + $WavePlayer__waveform-height-lg + $WavePlayer__video-height;
      }
    }
    &.withMediaElementAndBanner {
      @media (max-width: $WavePlayer__video-breakpoint) {
        margin-top: $app-bar-height-lg + $md-toolbar-height-lg + $WavePlayer__waveform-height-lg + $WavePlayer__video-height + 80;
      }
    }
    &.isExpanded {
      @media (max-width: $WavePlayer__video-breakpoint) {
        margin-top: $app-bar-height-lg + $md-toolbar-height-lg + $WavePlayer__waveform-height-lg + $WavePlayer__video-height-expanded;
      }
    }
    &.isExpandedWithBanner {
      @media (max-width: $WavePlayer__video-breakpoint) {
        margin-top: $app-bar-height-lg + $md-toolbar-height-lg + $WavePlayer__waveform-height-lg + $WavePlayer__video-height-expanded + 80;
      }
    }
    margin-top: $app-bar-height-lg + $md-toolbar-height-lg + $WavePlayer__waveform-height-lg;
  }
  @include resp('xlg') {
    margin-top: $app-bar-height-xlg + $md-toolbar-height-xlg + $WavePlayer__waveform-height-xlg;
  }  

  /**
   * The editor foreground (wrapper around `draft-js` element)
   */
  &__fg {
    box-sizing: border-box;
    position: relative;
    max-width: $Editor-maxwidth;
    margin: 0 auto;
    min-height: 100%;
    // padding: ($md-gutter) 0 $Editor-spacing-bottom;
    outline: none;
    white-space: pre-wrap; // as in trint.com
    line-height: 1.8;
    font-size: 18px;
    // background: $Editor-foreground;
    // color: $tttranscript__word-color-active;

    ::selection {
      background: hsla(270, 4.9%,71%, .38); // as in trint.com
    }
  }

  [data-block] {
    float: left;
    width: 100%;
  }
}

.TranscriptEditor p {
  margin: 0;
}

.editor_busy_indicator {
  color: black;
  font-weight: 500;
  font-size: 1em;
  background: white;
  border-radius: 5px;
  box-shadow: 2px 2px 6px gray;
  padding: 10px 20px;
}

/**
 * Transcript's row of content
 */
.block {
  position: relative;
  float: left;
  width: 100%;
  // padding: 0 $Editor__segment-horizontal-gutter

  .textLength {
    z-index: 1;
    position: absolute;
    font-size: 14px;
    top: $md-gutter / 4;
    right: $Editor__segment-horizontal-gutter;
    color: $Editor__word-color-inactive;
    user-select: none;
    color: $app-grey1;
    &.overLimit {
      color: $app-red;
    }
  }

  .txt {
    color: $Editor__word-color-inactive;
  }

  &.active .txt {
    color: $Editor__word-color-active;
  }

  .spoken_word {
    color: $Editor__word-color-active;
  }

  .failed_spoken_word {
    color: $Editor__failed-word-color-active;
  }

  .about_to_be_said {
    color: $Editor__word-color-active;
    border-bottom: 2px solid rgb(221, 221, 221);
    border-radius: 0;
  }

  .failed_about_to_be_said {
    color: $Editor__failed-word-color-active;
    border-bottom: 2px solid rgb(221, 221, 221);
    border-radius: 0;
  }

  &:hover {
    .txt {
      border-color: $Editor__speaker-input-bordercolor;
    }
    .TranscriptEditorSpeaker__input {
      color: $Editor__speaker-input-hover-color;
    }
    // .TranscriptEditorSpeaker {
    //   border-color: $Editor__speaker-input-bordercolor;
    // }
    // .TranscriptEditorSpeaker-placeholder {
    //   display: block;
    // }
  }
}

/**
 * A paragraph of text
 */
.txt {
  position: relative;
  padding: $Editor__segment-vertical-gutter $Editor__segment-horizontal-gutter;
  margin: 0;
  background: $Editor-foreground;

  @media (min-width: $Editor-maxwidth) {
    border-left: 2px solid transparent;
    margin-left: $Editor__speaker-width; // @@rtl
  }

  // [data-block="true"]:first-child & {
  //   padding-top: $md-gutter;
  // }

  [data-block="true"]:last-child & {
    padding-bottom: $Editor-spacing-bottom;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: $Editor-spacing-bottom;
      background: linear-gradient(to bottom, $Editor-foreground 0%, $Editor-background 100%);
    }
  }
}

  /**
   * The tinmestamp of each block
   */
.time {
  z-index: 1;
  position: absolute;
  font-size: 14px;
  top: $md-gutter / 4;
  left: $Editor__segment-horizontal-gutter;
  color: $md-primary;
  user-select: none;
  // pointer-events: none;
  & .start, & .end {
    cursor: pointer;
  }

  @media (min-width: $Editor-maxwidth) {
    left: $Editor__speaker-width + $Editor__segment-horizontal-gutter;
  }
}

.SpeakerDropdown--open {
  #Header,
  .WavePlayer {
    padding-right: 15px;
  }
  .EditorControls {
    padding-right: 31px;
  }
}

.TranscriptEditorEnvelope {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: $Editor-background;
}