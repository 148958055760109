@import '../../settings.scss';

.EditorControls {
  z-index: $EditorControls-z-index;
  position: fixed;
  left: 0;
  right: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 $md-gutter;
  font-weight: 500;
  background: #fff;
  text-align: center;

  @include resp('xs') {
    top: $app-bar-height-xs;
    height: $md-toolbar-height-xs;
    font-size: $font-size-xs;
  }
  @include resp('sm') {
    top: $app-bar-height-sm;
    height: $md-toolbar-height-sm;
    font-size: $font-size-sm - 1;
  }
  @include resp('md') {
    top: $app-bar-height-md;
    height: $md-toolbar-height-md;
    font-size: $font-size-md - 3;
  }
  @include resp('lg') {
    top: $app-bar-height-lg;
    height: $md-toolbar-height-lg;
    font-size: $font-size-lg - 4;
  }
  @include resp('xlg') {
    top: $app-bar-height-xlg;
    height: $md-toolbar-height-xlg;
    font-size: $font-size-xlg - 5;
  }

  &__buttons {
    display: flex;
    align-items: center;
  }

  &__startTimeOffset {
    cursor: pointer;
  }

  &__meta {
    display: flex;
    flex-direction: row;
    user-select: none;

    strong {
      margin-right: $md-gutter / 2; // @@rtl
      color: $md-primary;
      font-weight: normal;
    }

    em {
      margin-right: $md-gutter * 2; // @@rtl
      color: $md-body-color;
      font-style: normal;
    }

    time {
      display: inline-block;
      min-width: 68px; // to don't move the UI while the audio clock display updates each second
      text-align: left;
    }
  }

  button {
    min-width: 0;
    box-shadow: none !important;
    border-radius: 40px;
    padding-left: $md-gutter;
    padding-right: $md-gutter;
    min-height: 30px;
    line-height: 1;
    text-transform: none;

    @media (max-width: 1800px) {
      font-size: 0;
      padding: 2px 5px;
      border-radius: 100%;
      margin: ($md-gutter / 4) ($md-gutter / 2);
    }

    svg {
      position: relative;
      top: -2px;
      width: 18px;
      height: 18px;
      margin-right: 2px;
      fill: #6D6E6E;
    }
  }
  @media (max-width: 1130px) {
    &__startTimeOffset {
      display: none !important;
    }
  }
  @media (max-width: 960px) {
    &__created {
      display: none !important;
    }
  }
  @media (max-width: 800px) {
    .SpeedSlider {
      display: none !important;
    }
  }
  @media (max-width: 530px) {
    .btn_replace {
      display: none;
    }

    &__meta strong {
      display: none !important;
    }
  }

  @media (max-width: 530px) {
    &__meta {
      font-size: 13px !important;
      time {
        min-width: 44px;
      }
    }
  }
}

.UnActiveBtn {
  opacity: 0.5;
}