@import '../../settings.scss';

a {
  color: desaturate(darken($md-primary, 10%), 10%);
}
a:hover,
a:focus {
  color: desaturate(darken($md-primary, 20%), 20%);
}

.error,
.error a {
  color: $md-color-danger;
}

.text-light {
  color: $md-light-text;
}

.text-title {
  @include CO__small-title();
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.positive {
  color: $app-green;
}

.negative {
  color: $app-red;
}

.w-100 {
  width: 100%;
}
