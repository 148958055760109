@import '../../settings.scss';

video {
    width: auto !important;
}

.SubtitleWavePlayer {
    width: 100%;
    z-index: $WavePlayer-z-index;
    position: fixed;
    left: 0;
    right: 0;
    height: 200px;
    background-color: $md-subheader-bg;
    overflow-x: hidden;
    overflow-y: hidden;
    box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.05),
        0px 2px 12px 0px rgba(0, 0, 0, 0.035),
        0px 3px 11px -2px rgba(0, 0, 0, 0.03);

    bottom: -60px;

    &__loader {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    &__wavewrap {
        position: relative;
        cursor: pointer;
        outline-color: initial;
        outline-style: none;
        outline-width: initial;
        height: 100%;

        &.loading {
            visibility: hidden;
        }

        > wave {
            height: 90% !important;
            overflow: hidden !important
        }
    }

    &__highlight {
        z-index: 3;
        position: absolute;
        top: 0;
        bottom: 0;
        background: rgba(0, 90, 80, .35);
        pointer-events: none;
    }

    &__mediawrap {        
        transition: all $Video-resize-transmition-time ease-in-out;
        position: fixed;
        z-index: 2;

        @include resp('xs') {
            top: $app-bar-height-xs + $md-toolbar-height-xs + $WavePlayer__waveform-height-xs;
        }
        @include resp('sm') {
            top: $app-bar-height-sm + $md-toolbar-height-sm + $WavePlayer__waveform-height-sm;
        }
        @include resp('md') {
            top: $app-bar-height-md + $md-toolbar-height-md + $WavePlayer__waveform-height-md;
        }
        @include resp('lg') {
            top: $app-bar-height-lg + $md-toolbar-height-lg + $WavePlayer__waveform-height-lg;
        }
        @include resp('xlg') {
            top: $app-bar-height-xlg + $md-toolbar-height-xlg + $WavePlayer__waveform-height-xlg;
        }

        //small screens
        @media (max-width: $WavePlayer__video-breakpoint) {
            left: 0;
            right: 0;
            background-color: $Editor-background;
            overflow-x: hidden;
            overflow-y: hidden;
            z-index: 980;
            width: unset;
            max-width: unset;            
            &.isVideo {
                height: $WavePlayer__video-height;
                &.isExpanded {
                    height: $WavePlayer__video-height-expanded;
                }
            }
        }
        //large screens
        @media (min-width: $WavePlayer__video-breakpoint) {
            height: $WavePlayer__video-height-large-screens;
            &.isVideo {
                height: $WavePlayer__video-height;
                &.isExpanded {
                    height: $WavePlayer__video-height-expanded;
                }
            }
        }

        video {
            height: inherit;
            //small screens
            @media (max-width: $WavePlayer__video-breakpoint) {
                width: 100% !important;
                position: absolute;
                margin: auto;
            }
            //large screens
            @media (min-width: $WavePlayer__video-breakpoint) {
                width: unset !important;
            }
        }
    }
}


.Subtitle-audio__wrap {
    transition: all $Video-resize-transmition-time ease-in-out;
    height: $WavePlayer__video-height;
    padding-top: $WavePlayer__video-height - 25px - 60px;
    background-color: $WavePlayer__video-background-color;
    //small screen
    @media (max-width: $WavePlayer__video-breakpoint) {
        height: $WavePlayer__video-height;
        //video is expanded
        &.isExpanded {
            height: $WavePlayer__video-height-expanded;
        }
        width: 400px;
        margin: auto;
    }
    //large screen
    @media (min-width: $WavePlayer__video-breakpoint) {
        height: $WavePlayer__video-height-large-screens;
        //video is expanded
        &.isExpanded {
            height: $WavePlayer__video-height-large-screens-expanded;
        }
        width: 400px;
        margin: auto;
    }
}


.Subtitle {
    position: absolute;
    bottom: 0;
    margin-bottom: 2px;
    left: unset;
    width: 100%;
    height: unset;
    background-color: transparent;
    padding: 0 10px 5px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    color: #fff;

    
    //small screen
    @media screen and (max-width: $WavePlayer__video-breakpoint) {
        font-size: 10px;
        //video is expanded
        &.isExpanded {
            font-size: 12px;
        }
    }
    //large screen
    @media screen and (min-width: $WavePlayer__video-breakpoint) {
        font-size: 12px;
        //video is expanded
        &.isExpanded {
            font-size: 15px;
        }
    }

    span {        
        background: rgba(0,0,0,.6);
        border-radius: 6px;
        padding: 0;
        line-height: 13px;
        display: inline-block;
        padding: 3px 3px 1px;
        -webkit-transition: all .3s ease-out;
        -o-transition: all .3s ease-out;
        transition: all .3s ease-out;
    }

    &__line {
		overflow: hidden;
		display: block;
		text-overflow: ellipsis;
		white-space: nowrap;
    }
}

.resize_video {
    transition: all $Video-resize-transmition-time ease-in-out;
    z-index: 1000;
    position: absolute;
    top: 1px;
    color: white;
    cursor: pointer;
    @media screen and (max-width: $WavePlayer__video-breakpoint) {        
        font-size: 13px;
        left: calc(50% + 76px);
        //video is expanded
        &.isExpanded {
            font-size: 16px;
            left: calc(50% + 118px);
        }
    }
    @media screen and (min-width: $WavePlayer__video-breakpoint) {
        font-size: 24px;
        right: 1px;
    }
}

#amber-video-player {
    video {
        min-width: 100%;
        min-height: 100%;
        margin-left: 2px;
        height: auto;

        @include resp('xs') {
            height: 180px;
        }
        @include resp('sm') {
            height: 210px;
        }
        @include resp('md') {
            height: auto;
        }
        @include resp('lg') {
            height: auto;
        }
        @include resp('xlg') {
            height: auto;
        }
    }
}