@import '../../settings.scss';

.RewindButton {
  position: relative;  
  border-radius: 0;

  span {
    img {
      position: relative;
      border-radius: 100%;
      padding: 3px;
      background: #A5ACAF;
      box-shadow: $app-btn-boxshadow;
    }

    em {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      color: darken(#A5ACAF, 10%);
      font-style: normal;
      text-transform: none;
    }
  }

  
  @include resp('xs') {
    height: $app-bar-height-xs;
    width: $app-bar-height-xs;
    min-width: $app-bar-height-xs !important;
    em {
      font-size: 0;
    }
    img {
      top: 0px;
      width: $app-bar-height-sm / 2 ;
      height: $app-bar-height-sm / 2;
    }
  }
  @include resp('sm') {
    height: $app-bar-height-sm;
    width: $app-bar-height-sm;
    min-width: $app-bar-height-sm !important;
    em {
      font-size: $font-size-sm - 4;
    }
    img {
      top: -5px;
      height: $app-bar-height-sm / 2;
      width: $app-bar-height-sm / 2;
    }
  }
  @include resp('md') {
    height: $app-bar-height-md;
    width: $app-bar-height-md;
    min-width: $app-bar-height-md !important;
    em {
      font-size: $font-size-md - 6;
    }
    img {
      top: -5px;
      height: $app-bar-height-md / 2;
      width: $app-bar-height-md / 2;
    }
  }
  @include resp('lg') {
    height: $app-bar-height-lg;
    width: $app-bar-height-lg;
    min-width: $app-bar-height-lg !important;
    em {
      font-size: $font-size-lg - 6;
    }
    img {
      top: -5px;
      height: $app-bar-height-lg / 2;
      width: $app-bar-height-lg / 2;
    }
  }
  @include resp('xlg') {
    height: $app-bar-height-xlg;
    width: $app-bar-height-xlg;
    min-width: $app-bar-height-xlg !important;
    em {
      font-size: $font-size-lg - 6;
    }
    img {
      top: -5px;
      height: $app-bar-height-xlg / 2;
      width: $app-bar-height-xlg / 2;
    }
  }
}
