// Material variables
$body-font-size-base: 15px;

// AppBar Height
$app-bar-height-xs: 50px;
$app-bar-height-sm: 55px;
$app-bar-height-md: 60px;
$app-bar-height-lg: 64px;
$app-bar-height-xlg: 64px;

// ToolBar (Second AppBar) Height
$md-toolbar-height-xs: 35px;
$md-toolbar-height-sm: 40px;
$md-toolbar-height-md: 45px;
$md-toolbar-height-lg: 45px;
$md-toolbar-height-xlg: 50px;

// FontSize based on screen size
$font-size-xs: 12px;
$font-size-sm: 14px;
$font-size-md: 16px;
$font-size-lg: 18px;
$font-size-xlg: 20px;

// Footer size based on screen size
$app__footer-height-xs: 40px;
$app__footer-height-sm: 45px;
$app__footer-height-md: 50px;
$app__footer-height-lg: 55px;
$app__footer-height-xlg: 55px;

$md-default: #F7F7F7;
$md-primary: #005A50;
$md-secondary: #6C6E6E;
$md-color-danger: #E36A57; // rgb(221,44,0);
$md-gutter: 16px;
$md-light-text: #707273;
$md-line-height: 1.6em;
$md-subheader-bg: rgb(250,250,250);
$md-body-color: rgba(0,0,0,0.87);
$md-icon-color: rgba(0,0,0,0.54);
$md-input-color: $md-body-color;
$md-input-placeholder-color: rgba(0,0,0,0.38);
$md-input-border-color: rgba(0,0,0,0.12);

// Material UI@next
$MuiListItem-padding-vertical: 12px;
$MuiListItem-padding-horizontal: $md-gutter;

// Common variables
$md-brand-light: #F4F9FB;
$app__breakpoints: (
  xs: 600px,
  sm: 960px,
  md: 1280px,
  lg: 1920px,
);
$app__bg--light-accent: #fbfcfc;
$app-red: #E36A57;
$app-green: #5EC95F;
$app-yellow: #EE9A00;
$app-grey1: #A5ACAF;
$app-grey2: #EAEAEA;
$app-grey3: #F5F5F5;
$app-greydark: #979797;
$app-greydarker: #6D6E6E;
$app-btn-boxshadow: 0 0 $md-gutter rgba(0,0,0,.12);
$app__container-content-maxwidth: 960px;

// Components
$Header-bg: $md-default;
$Header-z-index: 1000;
$Header__button-circle-size: 34px;
$Header__button-circle-gutter: $md-gutter / 2;
$EditorControls-z-index: $Header-z-index - 10;

$WavePlayer__waveform-height-xs: 45px;
$WavePlayer__waveform-height-sm: 45px;
$WavePlayer__waveform-height-md: 50px;
$WavePlayer__waveform-height-lg: 50px;
$WavePlayer__waveform-height-xlg: 65px;

$WavePlayer-z-index: $EditorControls-z-index - 10;
$WavePlayer__video-height: 120px;
$WavePlayer__video-height-expanded: 180px;
$WavePlayer__video-height-large-screens: 200px;
$WavePlayer__video-height-large-screens-expanded: 260px;
$WavePlayer__video-height-extra-large-screens: 300px;
$WavePlayer__video-height-extra-large-screens-expanded: 360px;
$WavePlayer__video-background-color: #000000;
$WavePlayer__video-breakpoint: map-get($app__breakpoints, md) + 60px;
$WavePlayer__video-breakpoint-medium: map-get($app__breakpoints, md) + 360px;
$WavePlayer__video-breakpoint-large: map-get($app__breakpoints, lg);

$Video-resize-transmition-time: .3s;
$Editor-background: #f4f4f4;
$Editor-foreground: #ffffff;
$Editor-spacing-bottom: $md-gutter * 8;
$Editor__segment-vertical-gutter: $md-gutter * 1.5;
$Editor__segment-horizontal-gutter: $md-gutter * 2;
$Editor__speaker-width: 150px;
$Editor__speaker-border: 2px;
$Editor__speaker-input-focus-bg: #fafafa;
$Editor__speaker-input-hover-color: #666;
$Editor__speaker-input-color: #999;
$Editor__speaker-input-bordercolor: #ccc;
$Editor__speaker-input-height: 24px;
$Editor__speaker-clear-width: 15px;
$Editor__avatar-height: 50px;
$Editor__word-color-inactive: #666;
$Editor__word-color-active: #000;
$Editor__failed-word-color-active: #bf360c;
// $Editor__footer-height: $app__footer-height;
$Editor-maxwidth: 650px + $Editor__speaker-width + ($md-gutter * 4);

/**
 * Media query (min) mixin, see values at
 * https://material.angularjs.org/latest/layout/container
 */
@mixin resp($size) {
  @if $size == xs {
    @media (max-width: map-get($app__breakpoints, xs)) {
      @content;
    }
  }
  @if $size == sm {
    @media (min-width: map-get($app__breakpoints, xs)) and (max-width: map-get($app__breakpoints, sm)) {
      @content;
    }
  }
  @if $size == md {
    @media (min-width: map-get($app__breakpoints, sm)) and (max-width: map-get($app__breakpoints, md)) {
      @content;
    }
  }
  @if $size == lg {
    @media (min-width: map-get($app__breakpoints, md)) and (max-width: map-get($app__breakpoints, lg)) {
      @content;
    }
  }
  @if $size == xlg {
    @media (min-width: map-get($app__breakpoints, lg)) {
      @content;
    }
  }
}

@mixin CO__small-title($margin: false) {
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  color: $md-primary;

  @if $margin {
    margin: $margin;
  } @else {
    margin: ($md-gutter / 2) 0 $md-gutter;
  }
}

// joyride
$joyride-color: $md-primary;
$joyride-overlay-color: rgba(#000, 0.26);
$joyride-tooltip-color: #555; // $md-body-color
$joyride-tooltip-font-size: 16px;
$joyride-tooltip-padding: ($md-gutter * 2) ($md-gutter * 1.5) ($md-gutter * 1.5) ($md-gutter * 1.5);
$joyride-tooltip-arrow-size: 30px;
$joyride-hole-border-radius: 4px; // 100%;
$joyride-tooltip-border-radius: 4px;
$joyride-tooltip-width: (290px, 360px, 450px);
$joyride-beacon-color: $md-secondary; // $joyride-color;
$joyride-zindex: 1500;
$joyride-beacon-size: 36px;
$joyride-hole-shadow: 0 0 15px rgba(#000, 0.5);
$joyride-tooltip-bg-color: #fff;
$joyride-tooltip-shadow: (x: 1px, y: 2px, blur: 3px, color: rgba(#000, 0.3));
$joyride-close: (color: rgba($joyride-tooltip-color, 0.5), size: 12px, top: 10px, right: 10px);
$joyride-close-visible: true;
$joyride-header-color: $md-primary; // $joyride-tooltip-color;
$joyride-header-font-size: 26px;
$joyride-header-border-color: $joyride-color;
$joyride-header-border-width: 0;
$joyride-button-bg-color: $joyride-color;
$joyride-button-color: #fff;
$joyride-button-border-radius: 30px;
$joyride-back-button-color: $joyride-color;
$joyride-skip-button-color: lighten($md-light-text, 20%);
$joyride-tooltip-arrow-height: $joyride-tooltip-arrow-size / 1.5;
$joyride-tooltip-arrow-scale: ($joyride-tooltip-arrow-size / ($joyride-tooltip-arrow-size * 0 + 1)) / 16; // strip-units crazy hack
