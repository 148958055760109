@import '../../settings';

.AppBar {
    background-color: $md-default;
    z-index: 1000;
    display: flex;
    flex-direction: row;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: space-between;

    .title {
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 600;
        text-align: center;
        cursor: pointer;
        color: $md-primary;

        svg {
            vertical-align: middle;
            margin-left: 2px;
        }
    }

    .request_full_transcription {
        margin-right: 4px;
        padding: 2px;
        line-height: 1.4;
    }

    .account_badge {
        flex: 1;
        justify-content: center;
        align-items: center;
        display: flex;
        border: 2px solid #e36a57;
        color: #e36a57;
        padding: 5px 8px 4px;
        border-radius: 40px;
        font-weight: 700;
        cursor: pointer;
        
        &:hover {
            background: #E36A57;
            color: #fff;
        }
        span {            
            font-size: 12px;
            white-space: nowrap;
        }
    }

    @include resp('xs') {
        height: $app-bar-height-xs;
        .title {
            font-size: $font-size-xs + 2;
            svg {
                font-size: $font-size-xs + 2;
            }
        }
    }
    @include resp('sm') {
        height: $app-bar-height-sm;
        .title {
            font-size: $font-size-sm + 2;
            svg {
                font-size: $font-size-sm + 2;
            }
        }
    }
    @include resp('md') {
        height: $app-bar-height-md;
        .title {
            font-size: $font-size-md + 2;
            svg {
                font-size: $font-size-md + 2;
            }
        }
    }
    @include resp('lg') {
        height: $app-bar-height-lg;
        .title {
            font-size: $font-size-lg + 2;
            svg {
                font-size: $font-size-lg + 2;
            }
        }
    }
    @include resp('xlg') {
        height: $app-bar-height-xlg;
    }
}

.Appbar_left {
    display: flex;
    align-items: center;
}

.Appbar_right {
    display: flex;
    align-items: center;
    margin-right: 5px;
    
    strong {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    @include resp('xs') {
        .request_full_transcription {
            height: $app-bar-height-xs;
            display: none;
        }
        .account_badge {
            display: none;
        }
    }
    @include resp('sm') {
        .request_full_transcription {
            height: $app-bar-height-sm;
            width: $app-bar-height-sm + 40;
            margin-right: 2px;
            display: block;
            font-size: $font-size-sm - 2;
        }
        .account_badge {
            display: none;
        }
    }
    @include resp('md') {
        .request_full_transcription {
            height: $app-bar-height-md;
            width: $app-bar-height-md + 40;
            margin-right: 6px;
            display: block;
            font-size: $font-size-md - 2;
        }
        .account_badge {
            font-size: $font-size-md - 2;
            display: block;
        }

        strong {
            max-width: 140px;
        }
    }
    @include resp('lg') {
        .request_full_transcription {
            height: $app-bar-height-lg;
            width: $app-bar-height-lg + 40;
            margin-right: 14px;
            display: block;
            font-size: $font-size-lg - 4;
        }
        .account_badge {
            font-size: $font-size-lg - 2;
        }
    }
    @include resp('xlg') {
        .request_full_transcription {
            height: $app-bar-height-xlg;
            width: $app-bar-height-xlg + 40;
            margin-right: 14px;
            display: block;
            font-size: $font-size-lg - 4;
        }
        .account_badge {
            font-size: $font-size-lg - 2;
        }
    }
}