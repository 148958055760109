@import '../../settings.scss';

$dialog-gutter: $md-gutter * 1.5;

.dialog {

  &__content {
    background: transparent;
  }

  &__header {
    padding: $dialog-gutter;
  }

  &__close.md-button.md-icon-button {
    position: absolute;
    top: ($dialog-gutter / 4);
    right: ($dialog-gutter / 4);
    min-width: 40px;
    margin: 0;
  }

  &__title {
    color: $md-primary;
    font-size: 28px;
    font-weight: 600;
    margin: 0;

    ~ .dialog__subtitle {
      margin-top: $md-gutter / 2;
    }
  }

  &__subtitle {
    margin-top: 0;
  }

  &__body {
    padding: 0 $dialog-gutter $dialog-gutter $dialog-gutter;
  }

  &__footer {
    padding: 0 $dialog-gutter $dialog-gutter $dialog-gutter;

    .md-button {
      margin-bottom: 0;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
