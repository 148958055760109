@import '../../settings.scss';

.LangMenu {
  .HeaderButton {
    i {
      background: white;
      color: $md-icon-color;
    }

    strong {
      position: absolute;
      left: 41%;
      top: 52%;
      margin: 0;
      line-height: 1;
      font-size: 12px;
      background: #fff;
      border-radius: 100%;
      padding: 2px;
      color: grey;
    }
  }

  &__list {
    min-width: 256px;
  }

  @include resp('xs') {
    display: none;
  }
  @include resp('sm') {
    display: none;
  }
  @include resp('md') {
    display: none;
  }
  @include resp('lg') {
    display: block;
  }
}
