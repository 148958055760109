@import '../../settings.scss';

.SubtitleDialog {
	display: flex;

	&__form {
		width: 450px;
		.slider {
			padding-top: 14px;
			margin-bottom: -22px;
		}
		.note {
			font-size: 11px,
		}
		.advancedButton {
			color: $md-primary;
			cursor: pointer;
			font-weight: normal;
			text-align: center;
			margin-top: 44px;
		}
		.noteImage {
			width: 300px;
			display: block;
			margin: 15px auto;
	
		}
	}

	&__rightPanel {
		height: 492px;
		@media (max-width: 960px) {
			display: none;
		}
	}
}