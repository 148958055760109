@import '../../../settings.scss';

/**
 * Transcript's speaker in pargraph block,
 * just a clickable name
 */
.TranscriptEditorBlockSpeaker {
  display: none;
  float: left;
  padding: $Editor__segment-vertical-gutter 6px 0;
  width: $Editor__speaker-width - 30px;
  margin-left: 30px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border-right: 2px solid transparent;
  font-size: 14px;
  background: none;
  border: none;
  color: $md-primary; // $Editor__speaker-input-color;
  user-select: none;
  text-align: right;
  cursor: pointer;
  -webkit-appearance: none;

  @media (min-width: $Editor-maxwidth) {
    display: block;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:hover {
    color: $md-body-color;
  }

  &:after {
    z-index: 1;
    content: ":";
    position: absolute;
    left: $Editor__speaker-width;
    // 4 is the verticall padding of the speakers menu toggle button
    top: $Editor__segment-vertical-gutter;
    margin-left: 4px;
    pointer-events: none;
  }
}
