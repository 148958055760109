@import 'settings.scss';

@import 'assets/scss/common.dialog.scss';
@import 'assets/scss/common.home.scss';
@import 'assets/scss/common.utils.scss';

@font-face {
  font-family: 'Geomanist';
  src: url("./assets/fonts/Geomanist-Regular.otf") format("opentype");
}

@font-face {
  font-family: 'GeomanistBold';
  font-weight: bold;
  src: url("./assets/fonts/Geomanist-Bold.otf") format("opentype");
}

#root {
  display: flex;
  width: 100%
}

html {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

body {
  display: flex;
  flex: 1;
  overflow: auto;
  background: #fff;
  scroll-behavior: auto;
  margin: 0 !important;
}

body,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
input,
textarea {
  font-family: 'Geomanist', sans-serif !important;
}
