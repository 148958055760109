@import '../../settings.scss';

.footer_main_base {

    @include resp('xs') {
        height: $app__footer-height-xs;
    }
    @include resp('sm') {
        height: $app__footer-height-sm;
    }
    @include resp('md') {
        height: $app__footer-height-md;
    }
    @include resp('lg') {
        height: $app__footer-height-lg;
    }
    @include resp('xlg') {
        height: $app__footer-height-xlg;
    }
}

.footer_main {
    z-index: 1000;
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: space-between;
    background: $md-default;

    @include resp('xs') {
        font-size: $font-size-xs;
        .shortcutsHint {
            font-size: 0
        }
    }
    @include resp('sm') {
        font-size: $font-size-sm;
        .shortcutsHint {
            font-size: 12px;
        }
    }
    @include resp('md') {
        font-size: $font-size-md - 3;

        .shortcutsHint {
            margin-left: 10px;
        }
        .footer_right {
            margin-right: 10px;
        }

        span {
            font-size: 13px;
        }
    }
    @include resp('lg') {
        font-size: $font-size-lg - 4;

        .shortcutsHint {
            margin-left: 20px;
        }
        .footer_right {
            margin-right: 20px;
        }
    }
    @include resp('xlg') {
        font-size: $font-size-xlg - 4;

        .shortcutsHint {
            margin-left: 20px;
        }
        .footer_right {
            margin-right: 20px;
        }
    }

    .footer_left {        
        display: flex;
        align-items: center;
        height: 100%;
    }

    .footer_middle {
        display: flex;
        align-items: center;
        flex: 1;

        span {
            line-height: 15px;
        }

        @include resp('xs') {
            padding: 0 20px;
        }
        @include resp('sm') {
            padding: 0 20px;
        }
        @include resp('md') {
            padding: 0 20px;
        }
        @include resp('lg') {
            padding: 0 20px;
        }
        @include resp('xlg') {
            padding: 0 20px;
        }
    }

    .footer_right {        
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}