@import '../../settings.scss';

.Shortcuts {
  border: 0;

  &__table {
    width: 100%;
    border-collapse: collapse;
    padding: 0;
    // margin: ($md-gutter * 2) 0;

    td {
      color: $md-light-text;
      padding: ($md-gutter / 1.5) 0;

      &:hover {
        // background: $md-brand-light;
        color: $md-body-color;

        .Shortcuts__key {
          background: $md-primary;
          color: #fff;
        }
      }
    }
  }

  &__label {
    box-sizing: border-box;
    float: left;
    width: 50%;
    padding-right: $md-gutter;
  }

  &__shortcut {
    float: left;
    width: 50%;
    white-space: nowrap;
  }

  &__key {
    color: $md-primary;
    padding: 5px 4px 2px;
    border-radius: 4px;
  }

  &__plus {
    color: $md-primary;
    margin: 0 4px;
  }
}
