@import '../../settings.scss';
@import 'react-joyride/lib/react-joyride';

.joyride-tooltip[data-target="#TourModal"] {
  top: 50% !important;
  margin-top: -91px; // @todo this should be set in javascript dynamically
}

.joyride-tooltip {
  &__button {
  	&--skip {
  		min-height: 25px;
  	}
  }
}
